<template>
  <div>
    <Label v-bind="{labelStr: label, required: required}"></Label>
    <select
      class="
        input--default
        h-10 block w-full rounded shadow-sm px-2
      "
      v-bind:value="modelValue"
      v-on:input="$emit('update:modelValue', $event.target.value)"
    >
      <option value="" selected>{{ DEFAULT_SELECT_STR }}</option>
      <option
        v-for="option in options"
        v-bind:key="option.id"
        v-bind:value="option.id"
      >
        {{ option.str }}
      </option>
    </select>
    <div v-if="errors && errors[field]">
      <p class="text-error-500">{{errors[field][0]}}</p>
    </div>
  </div>
</template>

<script>
import { DEFAULT_SELECT_STR } from '@/components/js/constants.js'
import Label from '@/components/label.vue'

export default {
  name: 'Select',
  components: {
    Label
  },
  data () {
    return {
      DEFAULT_SELECT_STR: DEFAULT_SELECT_STR
    }
  },
  props: {
    errors: Object,
    field: String,
    label: String,
    modelValue: String,
    options: Array,
    required: Boolean
  }
}
</script>
