<template>
  <div class="project-clone">
    <Page title="Clone a project">
      <p>Cloning from <span class="font-bold">{{source.title}}</span>. All project line items and values will be carried over.</p>
      <div class="mt-8 max-w-md">
        <div class="grid grid-cols-1 gap-6">
          <Input
            field="title"
            inputclass="input--default"
            label="Title"
            required type="text"
            v-bind:errors="errors"
            v-model="clone.title"
          />
          <Input
            field="description"
            inputclass="input--default"
            label="Description"
            required type="text"
            v-bind:errors="errors"
            v-model="clone.description"
          />

          <Select
            v-if="!newClient"
            field="client"
            label="Client"
            required
            v-bind:errors="errors"
            v-bind:options="selectOptions(this.$store.state.clients, 'name')"
            v-model="clone.client"
          />
          <Input
            v-else
            field="client"
            inputclass="input--default"
            label="Client"
            type="text"
            required
            v-bind:errors="errors"
            v-model="clone.client_name"
          />
          <Button
            @click="toggleNewClient()"
            class="
              h-10 mb-0 w-full flex flex-row items-center mx-0
              transition-colors text-primary border-primary
              hover:text-primary-dark hover:border-primary-dark
            "
            buttontype="border"
            type="button">
              <span class="block flex-auto mr-3">
                {{ newClient ? 'Existing client' : 'Add new client' }}
              </span>
              <Icon
                v-if="!newClient"
                class="block flex-none h-6 w-6"
                name="plus"/>
          </Button>

          <Input
            field="point_of_contact"
            inputclass="input--default"
            label="Point of contact"
            required type="text"
            v-bind:errors="errors"
            v-model="clone.point_of_contact"
          />
          <moneyInput
            field="client_budget_pence"
            inputclass="input--default"
            label="Client budget (£)"
            v-bind:errors="errors"
            v-model="clone.client_budget_pence"
          />
          <Input
            field="client_discount_pc"
            inputclass="input--default"
            label="Client discount (%)"
            type="number"
            v-bind:errors="errors"
            v-model="clone.client_discount_pc"
          />
          <Input
            field="production_fee_pc"
            inputclass="input--default"
            label="Mark-up (%)"
            type="number"
            v-bind:errors="errors"
            v-model="clone.production_fee_pc"
          />
        </div>
      </div>
      <div class="mt-8 max-w-md">
        <Button buttontype="background"
        type="button"
        size="large"
        class="max-w-sm mx-auto"
        @click="handleClone">Clone project</Button>
      </div>
    </Page>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Page from '@/components/page.vue'
import Button from '@/components/button.vue'
import Input from '@/components/input.vue'
import Select from '@/components/select.vue'
import MoneyInput from '@/components/moneyInput.vue'
import { selectOptions, postRequest } from '@/components/js/utils.js'

export default {
  name: 'ProjectClone',
  components: { Page, Button, Input, MoneyInput, Select },
  data () {
    return {
      newClient: false
    }
  },
  computed: {
    ...mapState({
      source: state => state.projects.project,
      clone: state => state.projects.projectClone,
      errors: state => state.formValidationErrors
    })
  },
  methods: {
    selectOptions: selectOptions,
    handleClone () {
      function postCloneProject (ctx) {
        ctx.$store.dispatch('postCloneProject', {
          sourceId: ctx.$route.params.id,
          clone: ctx.clone
        }).then((ctx1 = ctx) => {
          ctx1.$router.push({ name: 'Home' })
        })
      }
      if (this.$data.newClient) {
        postRequest('client', { name: this.clone.client_name }, (res) => {
          this.clone.client = res.data.id
          postCloneProject(this)
        })
      } else {
        postCloneProject(this)
      }
    },
    toggleNewClient () {
      this.$data.newClient = !this.newClient
    }
  },
  mounted () {
    this.$store.dispatch('getClients')
    this.$store.dispatch('getProject', this.$route.params.id).then(() => {
      this.$store.dispatch('setProjectClone', this.source)
    })
    this.$store.commit('setFormValidationErrors', null)
  }
}
</script>
